<template>
  <component :is="'button'" v-if="!isLink" :class="[...classes]" :type="props.type">
    <slot />
  </component>
  <div v-else class="flex items-center">
    <a :href="href" :class="[...classes]" class="w-full"><slot /></a>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'button',
    validator: (v: string) => {
      return ['button', 'submit'].includes(v)
    },
  },
  outline: {
    type: Boolean,
    default: false,
  },
  isLink: {
    type: Boolean,
    default: false,
  },
  href: {
    type: String,
    default: '',
  },
  isInTable: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'primary',
    validator: (v: string) => {
      return ['primary', 'black', 'warning', 'gray'].includes(v)
    },
  },
})

const classes = computed(() => {
  let colorClasses: string[] = []
  if (props.theme === 'primary') {
    colorClasses = [
      'bg-green-main',
      'hover:bg-green-dark',
      'disabled:bg-green-disabled',
      'text-white',
    ]
  }
  if (props.theme === 'black') {
    colorClasses = [
      'bg-black-main',
      'hover:text-green-main',
      'disabled:bg-black-light',
      'text-white',
    ]
  }
  if (props.theme === 'warning') {
    colorClasses = [
      'bg-warning-main',
      'hover:bg-warning-hover',
      'disabled:bg-warning-light',
      'text-black',
      'border-black',
      'border',
    ]
  }

  if (props.theme === 'gray') {
    colorClasses = [
      'bg-gray-300',
      'hover:bg-gray-400',
      'disabled:bg-gray-100',
      'text-black',
      'border-black',
      'border',
    ]
  }
  const mainClasses = ['px-5 py-2 rounded transition ease-in-out text-center']

  const tableClasses = ['px-3 py-1']
  let allClasses = [...colorClasses, ...mainClasses]
  if (props.isInTable) {
    allClasses = [...allClasses, ...tableClasses]
  }
  return allClasses
})
</script>

<style scoped></style>
